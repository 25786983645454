.job-posting
    clear both
    padding-bottom 2rem
    overflow auto
    &:not(:first-child)
        border-top 1px solid #eee
        padding-top 2rem
    &__text
        font-size 1.1rem
    &__content
        lost-column 7/10 2 80px
        +below('m')
            lost-column 1/1
    &__files
        lost-column 3/10 2 80px
        +below('m')
            lost-column 1/1

