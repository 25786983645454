.infobox
    background bright
    color #fff
    padding gutter * 2
    margin-top gutter * -1
    font-weight normal
    font-size 1.2rem
    &--confirm
        max-width 750px
        margin 0 auto
    &__title
        margin-top 0
        font-size 1.8rem
    &__subtitle
        margin-top 2rem
        margin-bottom 1rem
        font-size 1.4rem
        display inline-block
        width 100%
        border-bottom 1px solid #fff
    .btn
        display block
        width 100%
        font-size 1.3rem
        cursor pointer

    .cancel
        margin 0
        color #fff
        font-size .9rem
        text-align right
        a
            color #fff

.daterangepicker td.in-range
    background-color lighten(bright, 52) !important

.daterangepicker td.active, .daterangepicker td.active:hover
    background-color bright !important
.daterangepicker .calendar
    padding-top 0 !important
    margin-top 0 !important

.daterangepicker .input-mini.active
    border-color neutral !important
.daterangepicker .input-mini
    font-size 14px !important
    border-radius 2px !important
    box-shadow none !important

.infobox-selector
    display flex
    justify-content space-between

    &__infobox
        cursor pointer
        text-align center
        border 2px solid white
        color #fff
        flex 0 48%
        &--is-active
            background #fff
            color bright
        &:focus
            box-shadow 0 0 20px rgba(0,0,0,.5)
