html
    +below('s')
        font-size 95%

body
    font-family $font-family-sans-serif;
    font-weight 400
    font-size 16px
    color #000
    line-height 1.6
    // lost-utility edit

    &.navigation-fixed
        padding-top 43px

a
    color primary-color
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd

.fa
    display inline-block