.main
    lost-utility clearfix
    color #444
    min-height 400px
    min-height 40vh

.page-heading
    background #f7f7f7
    border-bottom 1px solid #eee

    h1
        @extend .main-heading
        margin .8em 0
        line-height 1.4

.content,
.content-lead,
.sidebar
    padding 1em 0 2em
    font-size 1.1rem
    line-height 1.7
    margin-top .5em
    color black

.content-lead
    padding 1em 0 0

.content
    lost-column 1/1
    font-size 1.1rem

    h1
        @extend .main-heading

        p
            margin 0 0 1em

    h2
        font-size 1.4rem
    h4
        color primary-color
    ul
        padding-left 24px

    hr
        padding-top 1rem
        margin-bottom 2rem
        border-bottom 1px solid #eee

    &--with-sidebar
        lost-column 1/2 2 60px
        +below('m')
            lost-column 1/1
    &--with-sidebar-right
        lost-column 15/24 2 90px
        +below('m')
            lost-column 1/1

.sidebar
    lost-column 1/2 2 60px
    +below('m')
        lost-column 1/1
    ul
        padding-left 24px
    h2
        font-size 1.4rem
        &:first-child
            margin-top 0
        &:not(:first-child)
            margin-top 4rem

    &.sidebar--right
        lost-column 9/24 2 90px
        +below('m')
            lost-column 1/1
        +below('s')
            .btn
                width 100%


.right-floated-image
    float right
    max-width 300px
    margin-left 1rem
    margin-bottom 1rem