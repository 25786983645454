#go-to-top
    background neutral
    border-radius 50%
    position fixed
    box-shadow 0 2px 10px rgba(0,0,0,0.3)
    bottom 50%
    right 50px
    width 40px
    height 40px
    z-index 1000
    cursor pointer
    transform translate3d(100px, 50%, 0)
    transition .2s ease
    transition-property box-shadow, background-color, transform
    +below(1180px)
        bottom 50px
    +below('s')
        bottom 35px
        right 20px
    &.is-visible
        transform translate3d(0, 50%, 0)
    &:hover
        box-shadow 0 2px 10px rgba(0,0,0,.5)
        background-color bright

    img
        max-width 18px
        position absolute
        left 50%
        top 50%
        transform translate3d(-50%, -55%, 0)
