.modal
    opacity 0
    visibility hidden
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    background rgba(0, 0, 0, .9)
    z-index 20000
    overflow-y auto
    transition .3s ease-out
    transition-property opacity, visibility
    &--visible
        opacity 1
        visibility visible
    &__inner
        position absolute
        left 50%
        top 50%
        transform translate3d(-50%, -50%, 0)
        width 600px
        max-width 90%
        z-index 20001
        p
            margin 0
        a
            color #fff
            font-size .9em
            font-weight 600
        .video-js
            max-width 100%
            margin-bottom 0
