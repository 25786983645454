.team-list
    &__people
        lost-utility clearfix

        .img-box
            font-size .9rem
            lost-column 1/5
            +below('l')
                lost-column 1/4
            +below('s')
                lost-column 1/3
            +below('xs')
                lost-column 1/2
