.navigation
    color primary-color
    text-align center
    &--fixed
        position fixed
        width 100%
        top 0
        z-index 20000

    .wrapper
        background #fafafa
        border-bottom dark solid 2px

.nav-main
    width 100%
    font-size 0

    .nav-items
        +below('m')
            display none

.nav-items
    margin 0
    padding 0
    +above('l')
        display flex
        justify-content space-between

.nav-main .nav-item
    display inline-block
    padding 0
    margin 0
    font-size 1rem
    position relative
    &:first-child
        margin-left 0

    +below('l')
        font-size .9rem
    +below('m')
        margin 0 1em

    &--active > .nav-link,
    &--child-active > .nav-link,
    &:hover > .nav-link,
    &.nav-item--open > .nav-link
        background-color primary-color
        color white

    &.nav-item--open .nav-sub,
    &:hover .nav-sub,
    &:focus .nav-sub
        left 0

    &.nav-item--has-children
        a
            cursor default
        ul a
            cursor pointer



.nav-link
    display block
    color inherit
    font-weight normal
    padding .8em 1.2em
    &:hover
        color white
        background neutral
    +below('l')
        padding .7em 1em

.nav-main .nav-sub
    position absolute
    z-index 1
    padding-left 0
    left -99000px
    top 44px
    min-width 250px
    box-shadow 0 2px 3px rgba(0, 0, 0, .2)
    background neutral
    text-align left
    color white
    +below('l')
        top 44px
    .nav-item
        display block
        margin 0
        &:last-child
            .nav-link
                border-bottom-width 4px

    .nav-link
        display block
        margin 0
        padding .7em 1.2em
