.text-right
    text-align right !important

.text-center
    text-align center !important

.text-red
    color #f00

.text-sm-center
    +below('m')
        text-align center

.m-t
    margin-top 2em

.dim > div:not(:first-child)
    opacity .5

.hide-mobile
    +below('m')
        display none
.mobile-only
    display none
    +below('m')
        display block
