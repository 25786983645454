.lead
    min-height 500px
    +below('s')
        min-height 100px

    .slider-container
        width 300%
        position absolute
        left 0
        height 100%
        transition transform .9s ease
    .slider-image
        height 500px
        overflow hidden
        +below('s')
            height 200px

        .slide
            background #eee url('lead.jpg') no-repeat center 45%/auto 100%
            position relative
            width 1180px
            height 100%
            float left

    &--small
        height 25vh
        background-position center 35%
        min-height 320px

        +below('s')
            min-height 180px

    &__text
        width 36%
        position absolute
        right 0
        bottom 20%
        +below('m')
            width 100%
            bottom 5px
            text-align center
        .testimonial
            padding gutter
            padding-right gutter * 4
            background primary-color
            font-weight 600
            color #fff
            font-size 1.5rem
            font-style italic
            line-height 1.4
            span
                font-weight normal
                font-size .9rem
            +below('m')
                font-size 1.1rem
                padding gutter

        .btn
            font-weight 600
