transitionProperties = .5s cubic-bezier(.16, .68, .43, .99)

.hamburger
    display none
    position relative
    left 0
    top 0
    width 105px
    height 52px
    background url('hamburger.svg') no-repeat 10px center primary-color;
    padding-left 22px
    padding-top 12px
    font-size 1.5rem
    color #fff
    text-transform uppercase
    font-weight 500

    +below('m')
        display block
        padding-top 6px
        width 100%

    +below('s')
        padding-top 11px

.nav-offscreen
    .nav-item
        display block
        &:last-child .nav-link
            border-bottom 1px solid rgba(255,255,255,.8)

    .nav-link
        font-weight 500
        margin-top 0
        line-height 1.4
        padding 1em 1.2em
        margin-bottom 0
        border-top 1px solid rgba(255,255,255,.8)
        +below('s')
            font-size 1.3rem
    .nav-item--has-children .nav-link
        border-bottom 1px solid rgba(255,255,255,.8)
        +below('s')
            font-size 1.3rem

    .nav-sub
        display block
        margin-left 0
        padding-left 0
        position static
        .nav-link
            padding-left 50px
            font-weight normal
            border-bottom 1px solid rgba(255,255,255,.4)
            border-top none
        .nav-item:last-child .nav-link
            border-bottom none


.offscreen-nav
    width offscreen-nav-width
    height 100%
    background primary-color
    color #fff
    position fixed
    left 0
    top 0
    transform translate3d(-100%, 0, 0)
    transition transform transitionProperties
    z-index: 6000
    text-align left
    overflow-y auto
    will-change transform
    padding 0
    -webkit-overflow-scrolling touch

    .nav-item
        margin-left 0

    .nav-item--active .nav-link
        font-weight 600

.site-overlay
    background rgba(0,0,0,.8)
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    z-index: 5000
    visibility hidden
    opacity 0
    will-change opacity, visibility
    transition all transitionProperties

.site-wrapper, .nav-push
    transform translate3d(0, 0, 0)
    transition transform transitionProperties
    will-change transform

body.nav-visible
    background #333
    overflow-x hidden

    .site-wrapper
        overflow hidden
        background #fff

    .offscreen-nav
        transform translate3d(0, 0, 0)
        box-shadow 0 0 25px rgba(0,0,0,.4)

    .site-overlay
        visibility visible
        opacity 1
