/*
--------------------------------------------------------
Image Box
--------------------------------------------------------
Einfache Komponente für Bild + Titel + Beschreibung.

.image-box-gallery für 3 spaltige Auflistung
.image-box-gallery--small für 4 spaltige Auflistung

Markup:
--------------------------------------------------------

<div class="img-box-gallery img-box-gallery--small">
    {% for entry in entries %}
        <div class="img-box">
            <a class="img-box__link" href="{{ ('/portfolio/' ~ entry.slug) | app }}"></a>
            <div class="img-box__image">
                <img src="{{ image }}" alt=""/>
            </div>
            <h3 class="img-box__title">
                {{ entry.title }}
            </h3>
            <p class="img-box__description">
                {{ entry.customer }}
            </p>
        </div>
    {% endfor %}
</div>
*/

.img-box
    position relative
    margin-bottom 2em

    &__image img
        max-width 100%

    &__title
        margin .2em 0 .4em
        line-height 1.2
        text-transform uppercase

    &__link
        position absolute
        left 0
        top 0
        z-index 2000
        width 100%
        height 100%

    &__description
        color #666
        margin 0

    &--frontpage
        lost-column 6/8
        lost-offset -1/8
        +below('m')
            lost-column 8/8
            lost-offset 0



.img-box-gallery
    .img-box
        lost-column 1/3

        +below('m')
            lost-column 1/2

        +below('s')
            lost-column 1/1

    &--small
        .img-box
            lost-column 1/5

            +below('m')
                lost-column 1/4

            +below('s')
                lost-column 1/2

            +below('xs')
                lost-column 1/1
