.image-right
    lost-utility clearfix
    .image
        lost-column 1/3
        lost-move 2/3
        +below('m')
            lost-move 0
            lost-column 1/1
        img
            max-width 100%
            +below('m')
                margin 1rem 0

    .text
        lost-column 2/3
        lost-move -1/3
        +below('m')
            lost-move 0
            lost-column 1/1