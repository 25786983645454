.icon-block
    lost-utility clearfix
    &__icon
        lost-column 5/24 2 20px

        span
            background neutral
            color #fff
            text-align center
            display flex
            align-items center
            justify-content center
            width 57px
            height 57px
            font-size 1.8rem
            line-height 1
            .fa-shopping-bag
                margin-top -3px
            .fa-dropbox
                margin-top 1px
        +below('xs')
            lost-column 1/3 2 10px
    &__content
        lost-column 19/24 2 20px
        text-align justify
        hyphens auto
        a
            color inherit
        p
            margin-top 0
        +below('xs')
            lost-column 2/3 2 10px
    &__header
        color neutral
        font-weight normal
        font-size 1.6rem
        line-height 1
        margin 0 0 .4em
        padding 0

    +.icon-block
        margin-top 2rem