.cta
    background neutral
    color #fff
    padding 2em 0 2.4em
    lost-utility clearfix
    +below('l')
        text-align center

    .wrapper
        display flex
        +below('l')
            display block

    &__text
        lost-column 7/12
        +below('l')
            lost-column 1/1

    h2
        margin-top 0
        margin-bottom .25em
        line-height 1.4
        font-size 2.2rem
        font-weight normal

    p
        margin 0
        line-height 1.4
        font-size 1.3rem

    .phone-link
        font-weight 600
        color #fff
        font-size 2rem

    &__actions
        text-align right
        display flex
        align-items center
        margin-top .3em
        lost-column 5/12
        span
            margin 0 1rem
        +below('l')
            justify-content center
            lost-column 1/1
            margin-top 2rem
            text-align center
        +below('s')
            margin-top 1.6em
            flex-wrap wrap
            span
                margin .5rem 0 1rem
            & > *
                width 100%


    &__button

        +below('l')
            display block !important
            margin 0

            + .btn
                margin-top .5em
                margin-left 0 !important
