.image-list
    position relative
    margin-bottom 2em
    lost-utility clearfix
    padding-bottom 2rem
    border-bottom 1px solid #eee

    &--full-width .image-list__entry
        lost-column 1/1
        width 100% !important

    &__entry
        lost-column 1/2
        lost-utility clearfix
        margin-bottom 2rem
        +below('m')
            lost-column 1/1

    h4
        margin-top 0

    &__image
        lost-column 1/3 2
        +below('s')
            lost-column 1/1
            margin-bottom 1rem
        img
            max-width 100%
            display block

    &__text
        lost-column 2/3 2
        font-size 1rem
        +below('s')
            font-size 1.2rem
            lost-column 1/1
        p
            margin-top 0

    .btn
        +below('s')
            width 100% !important
            margin-bottom .5em
