.events
    margin-bottom 2rem
.events, .event
    list-style none
    margin-left 0 !important
    padding-left 0 !important

.event
    min-height 110px
    position relative
    border 2px solid primary-color
    transition max-height .5s ease
    +below('m')
        margin-bottom 4rem
    &--open
        max-height 999px

    + .event
        margin-top 1rem

    > a
        display flex

    &__date
        background #fafafa
        color primary-color
        font-weight 800
        font-size 3.5rem
        display flex
        align-items center
        justify-content center
        line-height 1
        width 110px
        margin-right 2rem
        text-align center
        .events--archived &
            color #d7d7d7

        +below('m')
            display none

        span
            font-weight normal
            display block
            font-size 1.4rem

    &__content
        display flex
        align-items center
        min-height 110px
        padding 1rem 1rem 1rem 0
        +below('m')
            padding-left 1rem

        h3
            font-weight 600
            margin 0
            padding 0
            +below('xs')
                font-size 1.1rem

    &__details
        display flex
        margin-left 0 !important
        padding-left 0 !important
        list-style none
        font-weight 400
        +below('m')
            flex-wrap wrap
            font-size 1rem
        +below('s')
            flex-direction column
            margin-top 1rem


        span
            margin-right .5rem
            svg
                height 15px
                width 15px
                fill primary-color

        li
            display flex
            align-items center
            margin-right 1.5rem
            +below('s')
                align-items flex-start
                span
                    padding-top 2px
            &:last-child
                margin-right 0

    &__link
        position absolute
        bottom 0
        right 0
        background primary-color
        color white
        padding .15rem .75rem
        +below('m')
            right -2px
            bottom auto
            top 100%
        div
            display inline-block
            padding-left 20px
            span
                display inline-block
                transform rotate(90deg)
                .event--open &
                    transform rotate(-90deg)
        &:before
            content "weiterlesen"
            .event--open &
                content "weniger"

    &__more
        max-height 0
        overflow hidden
        transition max-height .5s ease, border-top .5s ease, padding-bottom .5s ease
        border-color white
        .event--open &
            max-height 999px
            padding-bottom 2rem
            +below('s')
                border-top 10px solid #fafafa
                padding-top 1rem
                padding-bottom 0
        border-left 110px solid #fafafa
        padding-left 2rem
        display flex
        align-items flex-start
        +below('m')
            border-left none
            padding-left 1rem
        +below('s')
            flex-direction column

    .more__row
        flex-grow 1
        font-size .9.5rem
        p
            padding-top 0
            margin-top 0
            &:last-child
                margin-bottom 0
        &:first-child
            margin-right 2rem

        .more__link
            display flex
            span
                margin-right .5rem
            svg
                height 15px
                width 15px
                fill primary-color

    .more__row+.more__row
        border-left 1px solid gray
        padding-left 2rem
        +below('s')
            padding-left 0
            border-left none

