.skiplinks
    width 0
    height 0
    padding 0
    margin 0

    li
        display inline

        a
            display block
            position absolute
            left -3000px
            top -2000px
            width 0
            height 0
            z-index 200

            &:focus
                position absolute
                background-color #000
                color #FFF
                display block
                font-size 1.3em
                height auto
                width auto
                top 0
                left 0
                line-height 1.2em
                font-weight normal
                padding .5em 1em
                z-index 10000000
                text-decoration none

.skiplink-anchor,
.visually-hidden
    display block !important
    border 0 none !important
    margin 0 !important
    padding 0 !important
    background transparent !important
    outline 0 none !important
    line-height 0 !important
    height 0 !important
    font-size 0 !important
    overflow hidden !important


.screen-reader-only
    clip rect(1px, 1px, 1px, 1px)
    height 1px
    width 1px
    overflow hidden
    position absolute !important

// Invertiere invertierte Bilder zurück zu normalen Farben.
@media (inverted-colors: inverted)
    img:not(.no-invert), video, .lead-slider .slide
        filter invert(1)
    .video-js.vjs-fluid
        background #000
        filter invert(1)
