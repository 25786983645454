.marketplace-entry-list:after
    content ""
    display table
    clear both

.marketplace-entry-list .marketplace-entry
    lost-column 1/3
    +below('m')
        lost-column 1/2
    +below('s')
        lost-column 1/1

.marketplace-entry
    margin-bottom 7rem
    &__date
        margin 0
    &__title
        margin 0
    &__subtitle
        margin 0
        font-size 1.2rem
        color #666
    &__zip
        margin 0
        font-size 1.1rem
        color #666
    &__image
        margin-top 1rem
        margin-bottom 1rem
        position relative
        height 250px
        background #eee
        overflow hidden
        img
            display block
            width 100%
            max-width 100%
            position absolute
            left 50%
            top 50%
            transform translate3d(-50%, -50%, 0)

    &__price
        position absolute
        bottom 0
        left 0
        background primary-color
        color #fff
        padding .5rem

    &__text
        font-size 1.1rem
        margin 1rem 0 2rem
    &__actions
        margin-top 1rem
    &__other-images
        height 100%
    &__other-image
        lost-column 1/3
        height 80px
        img
            max-width 100%
            max-height 100%
            object-fit cover
            font-family 'object-fit: cover;'
            overflow hidden

.marketplace-contact
    margin-top 3rem
    border-top 1px solid #eee
    padding-top 1rem