.news-list
    lost-utility clearfix

.news
    padding 3em 0 0
    overflow auto
    clear both

    .btn
        color primary-color

    a
        text-decoration underline
        text-underline-offset 4px

    &__title
        margin-top -.5rem
        margin-bottom .25rem
        line-height 1.3
        font-size 1.8rem
        font-weight 600
        color primary-color
        +below('m')
            font-size 1.4rem
            
    &__teaser
        font-weight 600

    &__meta
        color #666
        font-weight 400
        margin-bottom 1em
        margin-top 0

    &__date
        font-weight 500
        margin-bottom 1em

    &__image
        lost-column 6/18 2 80px
        +below('m')
            background #f7f7f7
            lost-column 1/1
            margin-bottom 1.5rem
        img
            max-width 100%
            display block
            border-radius 2px
            margin-top 41px
            +below('m')
                margin 0 auto

    &__text
        lost-column 12/18 2 80px
        font-weight 400
        +below('m')
            lost-column 1/1
        &--no-image
            lost-column 1/1


.news-entry
    &__images
        float right
        margin-left 20px
        margin-bottom 20px
