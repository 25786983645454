.vjs-fluid
    padding-top 0 !important

.two.columns
    lost-utility clearfix
    .column
        lost-column 1/2

        +below('m')
            lost-column 1/1


.bs-callout.bs-callout--inverse
    background transparent
    color primary-color
    border 1px solid primary-color
    font-size 1rem
    a
        border-bottom 1px solid primary-color

.responsiv-uploader-fileupload.responsiv-uploader-fileupload.style-file-multi .upload-object:hover
    background-color primary-color !important

.responsiv-uploader-fileupload.responsiv-uploader-fileupload .upload-object img
    width: 100%;
    height: 40px;
    display: block;
    margin: auto;
    object-fit: contain;

.responsiv-uploader-fileupload.responsiv-uploader-fileupload.style-file-multi .upload-object .icon-container
    padding: 2px;
    height: 40px;

.team-image img
    filter: blur(7px)
    transition .3s ease-in-out filter

    &:hover, &:active
        filter: blur(0px)