.bordered-box
    background #fafafa
    border 1px solid #cccccc
    padding gutter * 2
    margin 0 (gutter * 2 * -1)
    font-size 1.1rem
    +between(400px, 450px)
        margin 0
    +between(700px, 760px)
        margin 0
    +below(356px)
        margin 0

    .three.columns
        lost-utility clearfix
        .column
            lost-column 1/3
            &--double
                lost-column 2/3
            +at('m')
                &:not(.marketplace)
                    lost-column 1/2
                &.marketplace
                    lost-utility clearfix
                    lost-column 1/2
                    margin-top 2rem
            +below('s')
                lost-column 1/1
                &:last-child
                    margin-top 2rem
            .square
                padding-top 100%

    .homepage-news
        +below('s')
            margin-top 2rem
        &__title
            font-weight 400
            font-size 1.7rem
            line-height 1.2
            margin 0
            padding 0
        &__date
            margin 0
            padding 0
            font-size 1.2rem
            font-weight 400
            color neutral
        &__text
            text-align justify
            line-height 1.6
            hyphens auto