.footer
    lost-utility clearfix
    color primary-color
    background #fff
    font-weight normal
    font-size 1rem
    padding-bottom .4rem
    +below('xs')
        font-size 1.2rem
    p
        margin 0
    .wrapper
        padding 0 0 .75em
        display flex
        align-items flex-start
        +below('m')
            display block
            padding 0 gutter

        > div
            width 100%

    &__logo
        lost-column 2/12 4
        +below('m')
            lost-column 1/2 2
        +below('s')
            lost-column 1/1
        img
            display block
            max-width 100%
            //position absolute
            top 29px
            +below('m')
                margin-bottom 0
            +below('s')
                position relative
                top 0
                left 14px

    &__address
        lost-column 4/12 4
        display flex
        .icon
            flex 0
        p
            margin-top 1.5rem
            margin-left 1rem

        +below('m')
            lost-column 1/2 2
            p
                margin-left 1rem
            .icon
                margin-left .25rem

        +below('s')
            lost-column 1/1
            margin 1rem 0

    &__contact
        lost-column 3/12 4
        +below('m')
            lost-column 1/2 2
            margin 1rem 0
        +below('s')
            lost-column 1/1 1

        ul
            list-style none
            margin 0
            padding 0
            margin-top 1.6rem
            .fa
                display inline-block
                margin-right .25em
                width 20px

    &__directions
        lost-column 3/12 4
        +below('m')
            lost-column 1/2 2
            margin 1rem 0
        +below('s')
            lost-column 1/1 1

.directions
    list-style none
    margin 0
    padding 0
    margin-top 1.6rem
    padding-left 0 !important
    .fa
        display inline-block
        width 1.2em
        font-size 1.1rem
        margin-right .5em
        text-align center