.callout
    background primary-color
    color white
    margin 1rem 0
    line-height 1.6
    font-size 1.1rem
    padding 1rem 2.5rem 1rem 2rem
    +below('s')
        padding 1rem
    a
        color inherit
        &:hover
            text-decoration underline
    h2
        font-weight 600
        margin-top 0