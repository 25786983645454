.main-heading
    font-weight 400
    font-size 2.4rem
    display inline-block
    padding-right 1em
    line-height 1.4
    position relative
    &:after
        content ""
        position absolute
        right 0
        bottom 4px
        width 0
        height 0
        border-style solid
        border-width 0 0 20px 20px
        border-color transparent transparent bright transparent
        +below('xs')
            border-width 0 0 12px 12px
            bottom 3px