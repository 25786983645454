@import 'bootstrap/forms'

.form-actions
    border-top 1px solid #e0e0e0
    padding 1rem
    margin 0 -1rem
    background #fafafa

.form-control
    padding 0.65em 0.6em 0.55em
    font-size 1rem
    height auto
    line-height 1
select.form-control
    padding 0 .5em
    height 37px
    font-size 0.9rem

textarea.form-control
    padding .5em .4em

.form-group
    .help-block
        font-size .8rem
        font-weight normal
        margin-bottom 0
    label
        margin-bottom 2px
        font-weight 500
        font-size 1rem

    &.has-error label
        color $state-danger-text

        .inline-error
            background $state-danger-text
            color $state-danger-bg
            display inline-block
            padding 4px 6px
            line-height 1
            font-size .8em
            border-radius 4px
            margin-left 10px
            margin-bottom 1px


.form-control:focus
    box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 3px rgba(64,72,85,.6)

.two.fields .form-group
    lost-column 1/2
    +below('s')
        lost-column 1/1

.three.fields .form-group
    lost-column 1/3
    +below('l')
        lost-column 1/2
    +below('m')
        lost-column 1/1

fieldset:not(:first-child)
    margin-top 3rem

.bs-callout
    padding 10px
    text-align center
    margin 20px 0
    color #fff
    background primary-color
    border-radius 3px

.error
    color red