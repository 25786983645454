.square
    display block
    position relative
    padding-top calc(99.9% * 1/4 - 20px) //  Ausgabe von lost-grid 1/4
    +below('m')
        padding-top calc(99.9% * 1/2 - 20px) //  Ausgabe von lost-grid 1/2
    +below('s')
        padding-top calc(99.9% * 1/1 - 20px) //  Ausgabe von lost-grid 1/1
    &__image
        top 0
        left 0
        height 100%
        width 100%
        position absolute
        img
            width 100%
            height 100%
            object-fit cover
            font-family 'object-fit: cover;'

    &__triangle
        position absolute
        right 0
        top 0
        width 0
        height 0
        border-style solid
        border-width 0 50px 50px 0
        border-color transparent bright transparent transparent

    &__title
        position absolute
        bottom 0
        width 100%
        background bright
        color #fff
        text-align center
        padding .5em 0
        font-size .9rem
        font-weight normal

.four.squares
    lost-utility clearfix
    margin 3rem 0
    .square
        lost-column 1/4
        +below('m')
            lost-column 1/2
            margin-bottom 2rem
        +below('s')
            lost-column 1/1
            margin-bottom 2rem