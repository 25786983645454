.header
    line-height 1
    background #fff
    .wrapper
        padding-top 1.25rem
        background #fff

    &__contact
        lost-column 1/2
        text-align right
        font-size .9rem
        font-weight normal
        margin-top -5px
        +at('s')
            .hide-mobile
                display none
        +below('s')
            lost-column 12 / 20 2
            margin -1rem 0 1rem
        +below('xs')
            lost-column 1/1
            text-align left
            margin .5rem 0 1rem

        ul
            margin 1.2rem 0 0
            list-style none
            line-height 1.4
            padding 0
            li
                margin-top 0
            a
                color #111
                display inline-block
                padding 3px 0
            .fa
                width 14px
                text-align right
                margin-left 15px
                font-size 1.1rem
                color neutral

                +below('xs')
                    margin-left 0
                    margin-right 15px
                    float left

.logo
    lost-column 1/2
    +below('s')
        lost-column 8/20 2
    +below('xs')
        lost-column 1/1

    a
        color inherit
        display block
        padding-bottom 1rem
        +below('s')
            padding .75rem 0
        +below('xs')
            padding 0

    img
        transform translateX(-130px)
        +below('xl')
            transform translate(0)
        max-width 100%
        display block
        +below('s')
            margin 4px gutter 0 7px
            max-width 100%
        +below('xs')
            margin 0 gutter 0 7px
            max-width "calc(100% - %s)" % (gutter * 2)

