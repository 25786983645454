.lead-slider
    .video-player
        opacity 0
        visibility hidden
        pointer-events none
        transition .3s ease opacity
        &--visible
            opacity 1
            visibility visible
            pointer-events all

    .video-js
        width 100% !important
        height 100% !important
        position absolute
        left 0
        top 0
    .slider-bottom
        color black

    .slider-nav
        background #eaeaea
        display flex
        list-style none
        padding 0
        margin 0
        line-height 1
        +below('m')
            flex-wrap wrap

        &__entry
            flex 1 0 auto
            font-size 1.05rem
            font-weight normal
            text-align center
            white-space nowrap
            +below('m')
                width 100%
            a
                color inherit
                display block
                padding 1rem 0
                &:hover,
                &:focus
                    background darken(#eaeaea, 10)

            &--is-active
                background neutral
                color #fff
                position relative
                a:hover,
                a:focus
                    background bright
                &:before
                    content ""
                    position absolute
                    top -10px
                    left 50%
                    width 0
                    height 0
                    border-style solid
                    border-width 0 7.5px 10px 7.5px
                    border-color transparent transparent neutral transparent
                    transform translate3d(-50%, 0, 0)
                    +below('m')
                        display none

.slide-caption-mobile
    color #fff
    font-size 1.1rem
    text-align center
    .wrapper
        background primary-color

    .testimonial
        padding gutter
        background primary-color
        font-weight 600
        font-style italic
        line-height 1.4
        span
            font-weight normal
            font-size .9rem

    .mobile-caption
        display none
        &--is-active
            display block