.boxes
    margin 1rem 0
.box
    lost-column 1/2 2 1rem
    +below('m')
        lost-column 1/1 1
        &+ .box
            margin-top 1rem
    .boxes--single &
        lost-column 1/1 1
    background #fafafa
    border 2px solid primary-color
    padding 1rem 2rem 1rem 1rem
    position relative
    &__outer
        display flex
        +below('m')
            display block
        a
            height 100%
    &__inner
        display flex
        +below('s')
            flex-direction column
            padding-bottom 1rem
    &__link
        padding .15rem .75rem
        background primary-color
        color white
        position absolute
        bottom 0
        right 0
    &__icon,
    &__image
        flex 1 1 auto
        display flex
        align-items center
        margin-right 1rem
    &__icon
        flex 0 1 auto
    &__image
        height 100%
        .image
            width 175px
            img
                max-width 100%
                display block
    &__icon
        width 70px
        .icon
            background primary-color
            width 70px
            height 70px
            display flex
            justify-content center
            align-items center
            svg
                fill white
                height 40px
                width 40px
    &__content
        flex 1 1 auto
        &--with-long-text
            padding-bottom 1rem
        color black
        +below('s')
            margin 1rem 0
        h3
            font-size 1.4rem
            margin-bottom .3rem
            line-height 1.25
            margin-top .15rem
            font-weight 600
            color primary-color

    &__subtitle
        color primary-color
        font-weight 600
        font-size .9rem
        margin 0