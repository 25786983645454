.dienstleistungen,
.dienstleistungen-lead
    width 80%
    min-width 800px
    margin 0 auto
    +below('m')
        min-width 0
        width 100%
    .text-with-image
        margin-bottom 6rem
        .lead
            lost-utility clearfix
            min-height 0
            lost-column 3/10 2
            +below('m')
                lost-column 1/1
                margin-bottom 1em

            a
                background neutral
                color #fff
                font-size 1rem
                text-align center
                padding .25em
                display block
        h3
            font-size 1rem
            font-weight 600
        .text
            font-size 1rem
            lost-column 7/10 2
            +below('m')
                lost-column 1/1

.text-with-image
    font-size 1.1rem
    lost-utility clearfix
    &--2-col
        width 100% !important
        .image
            lost-column 1/2
            +below('s')
                lost-column 1/1
            img
                display block
        .text
            lost-column 1/2
            +below('s')
                lost-column 1/1
    h2
        margin-top 0
        font-size 1.2rem
        line-height 1.5
    .image
        text-align center
        margin-bottom -8px
        &__caption
            color #777
            font-size 1rem
            margin 0
        img
            max-width 100%

.dienstleistungen-lead
    font-size 1.1rem
    margin-bottom 4rem

.image-list-betroffene
    .image-list__entry
        margin-bottom 5rem
    .image-list__text p.force-height
        min-height 110px